
import { Options, Vue } from 'vue-class-component'

import { DataSource_Machines } from '@/systems/data_sources/DataSource_Machines'; // @ is an alias to /src
import { Utilities } from '@/systems/common_code/Utilities';

@Options({
	async mounted () {
		const preMachines = document.getElementById ( "preMachines" );
		const machines = await DataSource_Machines.getMachines ( 1, 350 );
		// console.log ( machines );
		if ( preMachines ) {
			// let sMachinesHTML = "";
			// let i = 0;

			preMachines.innerHTML = '';

			// for ( i = 0; i < machines.length; i ++ ) { // machines/${machines [ i ].id
			// 	// ${sMachinesHTML}
			// 	sMachinesHTML = `<a href="#/machines/${machines [ i ].id}"><div>
			// 		<div>${machines [ i ].name}</div>
			// 		<div>${machines [ i ].description}</div>
			// 		<div>${machines [ i ].status}</div>
			// 		<div>${machines [ i ].configurationMachines.amountMachinesToRun}</div>
			// 	</div></a>`;
			// 	// const machine = await DataSource_Machines.getMachine ( machines [ i ].id );
			// 	// console.log ( machine );
			// 	const elementsMachines = Utilities.htmlToElements ( sMachinesHTML );
			// 	// console.log ( elementsMachines );
			// 	divMachines.append ( elementsMachines );
			// }
			// console.log ( sMachinesHTML );
			// const elementsMachines = Utilities.htmlToElements ( sMachinesHTML );
			// console.log ( elementsMachines );
			// divMachines.append ( elementsMachines );

			preMachines.append ( JSON.stringify ( machines, null, 2 ) );
		}
	},
	props: {
		msg: String
	}
})
export default class componentMachines extends Vue {
  msg!: string
}
