// import { config } from 'dotenv';

// config();

interface IConfiguration {
    env?: string;
    pagination: {
        limit: number;
    };
    parameters: {
        projectWork : string;
        environmentWork : string;
        passwordAdministrator : string;
        urlCoordinator : string;
        urlLogsMicroService : string;
        token : string;
    };
}

const configuration: IConfiguration = {
    env: process.env.NODE_ENV,
    pagination: {
        limit: 20
    },
    parameters: {
        projectWork : process.env.VUE_APP_WORK_PROJECT || 'Unspecified',
        environmentWork : process.env.VUE_APP_WORK_ENVIRONMENT || 'Unspecified',
        passwordAdministrator: process.env.VUE_APP_PASSWORD_ADMINISTRATOR || '',
        urlCoordinator: process.env.VUE_APP_URL_COORDINATOR || '',
        urlLogsMicroService: process.env.VUE_APP_URL_LOGS_MICRO_SERVICE || '',
        token : process.env.VUE_APP_TOKEN || '',
    }
};

export default configuration;
