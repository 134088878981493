
import { Options, Vue } from 'vue-class-component'

import { IProjectInputParameters, IProjectFromDataBaseFormatted, DataSource_Projects } from '@/systems/data_sources/DataSource_Projects'; // @ is an alias to /src
import { Utilities } from '@/systems/common_code/Utilities';

@Options({
	async mounted () {
		const divProject = document.getElementById ( "project" );
		const passwordAdministrator = this.getCookie ( "passwordAdministrator" );

		if ( divProject ) {
			if ( ! passwordAdministrator ) {
				divProject.innerHTML = 'Administrator password is not set, you will not be able to work with Project.';
				return;
			}

			let project : IProjectFromDataBaseFormatted | null = null;
			if ( this.idHumanReadable !== "new" ) {
				project = await DataSource_Projects.getProject (this.idHumanReadable);
			}
			console.log ( project );

			divProject.innerHTML = '';

			const sProjectHTML = `<div><table>
				<tr><td>Name</td><td><input type="text" id="edName" value="${project ? project.name : ""}"/></td></tr>
				<tr><td>Description</td><td><input type="text" id="edDescription" value="${project ? project.description : ""}"/></td></tr>
				<tr><td>Status</td><td><input type="checkbox" id="cbStatus" ${(!project || project.status === "active") ? "checked" : ""}/></td></tr>
				<tr><td>Amount of Machines</td><td><input type="number" min="0" step="1" id="amountMachinesToRun" value="${project ? project.configurationMachines.amountMachinesToRun : 0}"/></td></tr>
				<tr><td>CPU cores</td><td><input type="number" min="1" step="1" id="amountCPUCores" value="${project ? project.configurationMachines.amountCPUCores : 1}"/></td></tr>
				<tr><td>RAM GB</td><td><input type="number" min="1" step="1" id="gigabytesRAM" value="${project ? project.configurationMachines.gigabytesRAM : 1}"/></td></tr>
				<tr><td>GPU type</td><td><input type="text" id="typeGPU" value="${project ? project.configurationMachines.typeGPU : ""}"/></td></tr>
				</table><div id="divButtons"><input type="button" id="btNew" value="New"/> ${project ? '<input type="button" id="btSave" value="Save"/> <input type="button" id="btDelete" value="Delete"/>' : ''}</div>
			</div>`;
			const elementsProject = Utilities.htmlToElements ( sProjectHTML );
			divProject.append ( elementsProject );

			const btNew = document.getElementById ( "btNew" );
			if ( btNew ) {
				btNew.addEventListener("click", async ( inputEvent ) => {
					console.log ( "btNew : onclick : Button is clicked." );

					const edName = document.getElementById ( "edName" ) as HTMLInputElement;
					const edDescription = document.getElementById ( "edDescription" ) as HTMLInputElement;
					const amountMachinesToRun = document.getElementById ( "amountMachinesToRun" ) as HTMLInputElement;
					const amountCPUCores = document.getElementById ( "amountCPUCores" ) as HTMLInputElement;
					const gigabytesRAM = document.getElementById ( "gigabytesRAM" ) as HTMLInputElement;
					const typeGPU = document.getElementById ( "typeGPU" ) as HTMLInputElement;

					const projectNew = {
						"name": "TestProject",
						"description": "TestProject project.",
						"status": "active",
						"configurationMachines": {
						    "metaFields": [
						        "Test"
						    ],
						    "nameProvider": "CoreWeave",
						    "region" : "ORD1",
						    "nameManifestFile": "vm-machine-image.json",
						    "nameProviderAccount": "vitamotus-vm-clone",
						    "environmentProvider": "tenant-icvr",
						    "amountMachinesToRun": 0,
						    "amountCPUCores": 16,
						    "gigabytesRAM": 16,
						    "typeGPU": "Quadro_RTX_4000"
						},
						"configurationAuthentication": {
						    "groupsClients": [
						        "administrators"
						    ],
						    "nameTokensValidationKeyFile": "vm-tokens-keys.json"
						},
						"configurationListener": {
						    "metaFields": "{}"
						},
						"configurationApplication": {
						    "serversSTUN": "{\"peerConnectionOptions\":{ \"iceServers\":[{ \"urls\":[\"stun:stun.l.google.com:19302\"]}]}}",
						    "metaFields": "{}"
						},
						"metaFields": [
						    "{}"
						]
		            }

					if ( projectNew && edName && edDescription && amountMachinesToRun &&
					     amountCPUCores && gigabytesRAM && typeGPU
					) {
						projectNew.name = edName.value;
						projectNew.description = edDescription.value;
						projectNew.configurationMachines.amountMachinesToRun = amountMachinesToRun.valueAsNumber;
						projectNew.configurationMachines.amountCPUCores = amountCPUCores.valueAsNumber;
						projectNew.configurationMachines.gigabytesRAM = gigabytesRAM.valueAsNumber;
						projectNew.configurationMachines.typeGPU = typeGPU.value;
						const projectsAdded = await DataSource_Projects.addProjects ([projectNew], passwordAdministrator);
						console.log ( projectsAdded );
						window.location.href = `#/projects/${projectsAdded[0].id}`;
					}
				});
			}

			const btSave = document.getElementById ( "btSave" );
			if ( btSave && project ) {
				btSave.addEventListener("click", async ( inputEvent ) => {
					console.log ( "btSave : onclick : Button is clicked." );

					const edName = document.getElementById ( "edName" ) as HTMLInputElement;
					const edDescription = document.getElementById ( "edDescription" ) as HTMLInputElement;
					const amountMachinesToRun = document.getElementById ( "amountMachinesToRun" ) as HTMLInputElement;
					const amountCPUCores = document.getElementById ( "amountCPUCores" ) as HTMLInputElement;
					const gigabytesRAM = document.getElementById ( "gigabytesRAM" ) as HTMLInputElement;
					const typeGPU = document.getElementById ( "typeGPU" ) as HTMLInputElement;

					if ( project && edName && edDescription && amountMachinesToRun &&
						 amountCPUCores && gigabytesRAM && typeGPU
					) {
						const projectNew = {
							name : edName.value,
							description : edDescription.value,
							status : project.status,
							configurationMachines : project.configurationMachines,
							configurationAuthentication : project.configurationAuthentication,
							configurationListener : project.configurationListener,
							configurationApplication : project.configurationApplication,
							metaFields : project.metaFields
						}
						projectNew.configurationMachines.amountMachinesToRun = amountMachinesToRun.valueAsNumber;
						projectNew.configurationMachines.amountCPUCores = amountCPUCores.valueAsNumber;
						projectNew.configurationMachines.gigabytesRAM = gigabytesRAM.valueAsNumber;
						projectNew.configurationMachines.typeGPU = typeGPU.value;
						await DataSource_Projects.updateProject (this.idHumanReadable, projectNew, passwordAdministrator);
					}
				});
			}

			const btDelete = document.getElementById ( "btDelete" );
			if ( btDelete && project ) {
				btDelete.addEventListener("click", async ( inputEvent ) => {
					console.log ( "btDelete : onclick : Button is clicked." );

					if ( project ) {
						await DataSource_Projects.deleteProject (this.idHumanReadable, passwordAdministrator);
						window.location.href = '#/projects/';
					}
				});
			}
		}
	},
	methods : {
      getCookie : function ( cname : string ) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
  },
	props: {
		idHumanReadable : String
	}
})
export default class componentProject extends Vue {
	idHumanReadable : string = "";
}
