import axios from 'axios';

import configuration from '../../configuration';

const URL_PROJECTS = `${configuration.parameters.urlCoordinator}/v1.0/projects/`;
const token = `${configuration.parameters.token}_${Date.now ()}`;

interface IVirtualMachines {
    nameProvider : string;
    region : string;
    nameManifestFile : string;
    nameProviderAccount : string;
    environmentProvider : string;
    amountMachinesToRun : number;
    amountCPUCores : number;
    gigabytesRAM : number;
    typeGPU : string;
    metaFields : string [];
}

interface IAuthentication {
    nameTokensValidationKeyFile : string;
    groupsClients : string [];
}

interface IListenerConfiguration {
    metaFields : string;
}

interface IApplicationConfiguration {
    serversSTUN : string;
    metaFields : string;
}

interface IProjectBase {
    name : string;
    description : string;
    status : string;
    configurationMachines : IVirtualMachines;
    configurationAuthentication : IAuthentication;
    configurationListener : IListenerConfiguration;
    configurationApplication : IApplicationConfiguration;
    metaFields : string [];
}

export interface IProjectInputParameters extends IProjectBase {
}

export interface IProject extends IProjectBase {
    dateCreation: number;
}

export interface IProjectFromDataBaseFormatted extends IProject {
    id: string;
}

export class DataSource_Projects {
    static async addProjects ( inputProjects : IProjectInputParameters [], passwordAdministrator : string ) : Promise < IProjectFromDataBaseFormatted [] > {
        console.log ( `DataSource_Projects.addProjects () : Adding project : ${URL_PROJECTS}.` );

        const promiseProjects = new Promise < any > ( async (resolve : Function, reject : Function) => {
            axios.post (
                URL_PROJECTS,
                { passwordAdministrator, projects : inputProjects },
                { 
                    headers: {
                        "Authorization" : `Bearer ${token}`
                    }
                }
            )
            .then(function (response : any) {
                resolve (response.data);
            })
            .catch(function (error : any) {
                // handle error
                console.error(`DataSource_Projects.addProjects () : axios.post () error : ${JSON.stringify (error)} .`);
                if( error.response ){
                    console.error(`DataSource_Projects.addProjects () : ${JSON.stringify ( error.response.data )} .`);
                }
                reject ();
            });
        });

        const response = await promiseProjects;

        return response.data.projects;
    }

    static async getProjects () : Promise < IProjectFromDataBaseFormatted [] > {
        // const Projects = [] as IProjectInList [];

        console.log ( `DataSource_Projects.getProjects () : Receiving projects : ${URL_PROJECTS}.` );

        // const response = await axios.get(URL_PROJECTS);

        const promiseProjects = new Promise < any > ( async (resolve : Function, reject : Function) => {
            axios.get (
                URL_PROJECTS,
                { 
                    headers: {
                        "Authorization" : `Bearer ${token}`
                    }
                }
            )
            .then(function (response : any) {
                resolve ( response.data );
            })
            .catch(function (error : any) {
                // handle error
                console.error(`DataSource_Projects.getProjects () : axios.get () error : ${JSON.stringify (error)} .`);
                if( error.response ){
                    console.error(`DataSource_Projects.getProjects () : ${JSON.stringify ( error.response.data )} .`);
                }
                reject ();
            });
        });

        const response = await promiseProjects;

        // console.log ( response );
        // const Projects = JSON.parse ( stringProjects ) as IProjectInList [];
            // .then(response => (
            //  this.info = response
            // ));

        // const projects = response.data.projects;

        return response.data.projects;
        // return [];
    }

    static async getProject ( inputId : string ) : Promise < IProjectFromDataBaseFormatted | null > {
        const urlProject = `${URL_PROJECTS}${inputId}/`;
        console.log ( `DataSource_Projects.getProject () : Receiving project : ${urlProject}.` );

        const promiseProject = new Promise < any > ( async (resolve : Function, reject : Function) => {
            axios.get (
                urlProject,
                { 
                    headers: {
                        "Authorization" : `Bearer ${token}`
                    }
                }
            )
            .then(function (response : any) {
                resolve ( response.data );
            })
            .catch(function (error : any) {
                // handle error
                console.error(`DataSource_Projects.getProject () : axios.get () error : ${JSON.stringify (error)} .`);
                if( error.response ){
                    console.error(`DataSource_Projects.getProject () : ${JSON.stringify ( error.response.data )} .`);
                }
                reject ();
            });
        });

        const response = await promiseProject;

        return response.data.project;
    }

    static async updateProject ( inputId : string, inputProject : IProjectInputParameters, passwordAdministrator : string ) : Promise < void > {
        const urlProject = `${URL_PROJECTS}${inputId}/`;
        console.log ( `DataSource_Projects.updateProject () : Updating project : ${urlProject}.` );

        const promiseProject = new Promise < void > ( async (resolve : Function, reject : Function) => {
            axios.put (
                urlProject,
                { passwordAdministrator, project : inputProject },
                { 
                    headers: {
                        "Authorization" : `Bearer ${token}`
                    }
                }
            )
            .then(function (response : any) {
                resolve ();
            })
            .catch(function (error : any) {
                // handle error
                console.error(`DataSource_Projects.updateProject () : axios.put () error : ${JSON.stringify (error)} .`);
                if( error.response ){
                    console.error(`DataSource_Projects.updateProject () : ${JSON.stringify ( error.response.data )} .`);
                }
                reject ();
            });
        });

        await promiseProject;
    }

    static async deleteProject ( inputId : string, passwordAdministrator : string ) : Promise < void > {
        const urlProject = `${URL_PROJECTS}${inputId}/`;
        console.log ( `DataSource_Projects.deleteProject () : Deleting project : ${urlProject}.` );

        const promiseProject = new Promise < void > ( async (resolve : Function, reject : Function) => {
            // axios.delete (
            //     // ?passwordAdministrator=${encodeURI(passwordAdministrator)
            //     `${urlProject}`,
            axios (
                {
                    url :  urlProject,
                    method : "delete",
                    headers: {
                        "Authorization" : `Bearer ${token}`,
                        passwordAdministrator : passwordAdministrator
                    },
                    data : {
                        "passwordAdministrator" : passwordAdministrator
                    }
                }
            )
            .then(function (response : any) {
                resolve ();
            })
            .catch(function (error : any) {
                // handle error
                console.error(`DataSource_Projects.deleteProject () : axios.delete () error : ${JSON.stringify (error)} .`);
                if( error.response ){
                    console.error(`DataSource_Projects.deleteProject () : ${JSON.stringify ( error.response.data )} .`);
                }
                reject ();
            });
        });

        await promiseProject;
    }
}
