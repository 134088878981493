import axios from 'axios';

import configuration from '../../configuration';

const URL_MACHINES = `${configuration.parameters.urlCoordinator}/v1.0/machines/`;
const token = `${configuration.parameters.token}_${Date.now ()}`;

export interface IMachineBase {
    url ? : string;
    idInstance ? : string;
    typeLaunch ? : string;
    nameProject : string;
}

export interface IMachineInputParameters extends IMachineBase {
    timeMachineKeepAliveSignal ? : number;
}

export interface IMachine extends IMachineBase {
    timeMachineKeepAliveSignal : number;

    dateCreation: Date;
}

export interface IMachineFromDataBaseFormatted extends IMachine {
    id: string;
}

export class DataSource_Machines {
    // static async addMachines ( inputMachines : IMachineInputParameters [] ) : Promise < IMachineFromDataBaseFormatted [] > {
    //     console.log ( `DataSource_Machines.addMachines () : Adding machine : ${URL_MACHINES}.` );

    //     const promiseMachines = new Promise < any > ( async (resolve : Function, reject : Function) => {
    //         axios.post (
    //             URL_MACHINES,
    //             { machines : inputMachines },
    //             { 
    //                 headers: {
    //                     "Authorization" : `Bearer ${token}`
    //                 }
    //             }
    //         )
    //         .then(function (response : any) {
    //             resolve (response.data);
    //         })
    //         .catch(function (error : any) {
    //             // handle error
    //             console.error(`DataSource_Machines.addMachines () : axios.post () error : ${JSON.stringify (error)} .`);
    //             if( error.response ){
    //                 console.error(`DataSource_Machines.addMachines () : ${JSON.stringify ( error.response.data )} .`);
    //             }
    //             reject ();
    //         });
    //     });

    //     const response = await promiseMachines;

    //     return response.data.machines;
    // }

    static async getMachines ( inputPage : number = 1, inputAmountPerPage : number = 20 ) : Promise < IMachineFromDataBaseFormatted [] > {
        // const Machines = [] as IMachineInList [];

        console.log ( `DataSource_Machines.getMachines () : Receiving machines : ${URL_MACHINES}.` );

        // const response = await axios.get(URL_MACHINES);

        const promiseMachines = new Promise < any > ( async (resolve : Function, reject : Function) => {
            axios.get (
                `${URL_MACHINES}?page=${inputPage}&limit=${inputAmountPerPage}`,
                { 
                    headers: {
                        "Authorization" : `Bearer ${token}`
                    }
                }
            )
            .then(function (response : any) {
                resolve ( response.data );
            })
            .catch(function (error : any) {
                // handle error
                console.error(`DataSource_Machines.getMachines () : axios.get () error : ${JSON.stringify (error)} .`);
                if( error.response ){
                    console.error(`DataSource_Machines.getMachines () : ${JSON.stringify ( error.response.data )} .`);
                }
                reject ();
            });
        });

        const response = await promiseMachines;

        // console.log ( response );
        // const Machines = JSON.parse ( stringMachines ) as IMachineInList [];
            // .then(response => (
            //  this.info = response
            // ));

        // const machines = response.data.machines;

        return response.data.machines;
        // return [];
    }

    // static async getMachine ( inputId : string ) : Promise < IMachineFromDataBaseFormatted | null > {
    //     const urlMachine = `${URL_MACHINES}${inputId}/`;
    //     console.log ( `DataSource_Machines.getMachine () : Receiving machine : ${urlMachine}.` );

    //     const promiseMachine = new Promise < any > ( async (resolve : Function, reject : Function) => {
    //         axios.get (
    //             urlMachine,
    //             { 
    //                 headers: {
    //                     "Authorization" : `Bearer ${token}`
    //                 }
    //             }
    //         )
    //         .then(function (response : any) {
    //             resolve ( response.data );
    //         })
    //         .catch(function (error : any) {
    //             // handle error
    //             console.error(`DataSource_Machines.getMachine () : axios.get () error : ${JSON.stringify (error)} .`);
    //             if( error.response ){
    //                 console.error(`DataSource_Machines.getMachine () : ${JSON.stringify ( error.response.data )} .`);
    //             }
    //             reject ();
    //         });
    //     });

    //     const response = await promiseMachine;

    //     return response.data.machine;
    // }

    // static async updateMachine ( inputId : string, inputMachine : IMachineInputParameters ) : Promise < void > {
    //     const urlMachine = `${URL_MACHINES}${inputId}/`;
    //     console.log ( `DataSource_Machines.updateMachine () : Updating machine : ${urlMachine}.` );

    //     const promiseMachine = new Promise < void > ( async (resolve : Function, reject : Function) => {
    //         axios.put (
    //             urlMachine,
    //             { machine : inputMachine },
    //             { 
    //                 headers: {
    //                     "Authorization" : `Bearer ${token}`
    //                 }
    //             }
    //         )
    //         .then(function (response : any) {
    //             resolve ();
    //         })
    //         .catch(function (error : any) {
    //             // handle error
    //             console.error(`DataSource_Machines.updateMachine () : axios.put () error : ${JSON.stringify (error)} .`);
    //             if( error.response ){
    //                 console.error(`DataSource_Machines.updateMachine () : ${JSON.stringify ( error.response.data )} .`);
    //             }
    //             reject ();
    //         });
    //     });

    //     await promiseMachine;
    // }

    // static async deleteMachine ( inputId : string ) : Promise < void > {
    //     const urlMachine = `${URL_MACHINES}${inputId}/`;
    //     console.log ( `DataSource_Machines.deleteMachine () : Deleting machine : ${urlMachine}.` );

    //     const promiseMachine = new Promise < void > ( async (resolve : Function, reject : Function) => {
    //         axios.delete (
    //             urlMachine,
    //             { 
    //                 headers: {
    //                     "Authorization" : `Bearer ${token}`
    //                 }
    //             }
    //         )
    //         .then(function (response : any) {
    //             resolve ();
    //         })
    //         .catch(function (error : any) {
    //             // handle error
    //             console.error(`DataSource_Machines.deleteMachine () : axios.delete () error : ${JSON.stringify (error)} .`);
    //             if( error.response ){
    //                 console.error(`DataSource_Machines.deleteMachine () : ${JSON.stringify ( error.response.data )} .`);
    //             }
    //             reject ();
    //         });
    //     });

    //     await promiseMachine;
    // }
}
