import axios from 'axios';

import configuration from '../../configuration';

const URL_APPLICATIONS = `${configuration.parameters.urlCoordinator}/v1.0/applications/`;
const token = `${configuration.parameters.token}_${Date.now ()}`;

export interface IMachineApplicationBase {
    idMachine : string;
    idClient ? : string;
    nameProject : string;
    idProcess : string;
    pathReceiveMessages : string;
}

export interface IMachineApplicationInputParameters extends IMachineApplicationBase {
    timeClientKeepAliveSignal ? : number;
    timeMachineApplicationKeepAliveSignal ? : number;
}

export interface IMachineApplication extends IMachineApplicationBase {
    timeClientKeepAliveSignal : number;
    timeMachineApplicationKeepAliveSignal : number;

    dateCreation: Date;
}

export interface IMachineApplicationFromDataBaseFormatted extends IMachineApplication {
    id: string;
}

export class DataSource_Applications {
    // static async addApplications ( inputApplications : IApplicationInputParameters [] ) : Promise < IApplicationFromDataBaseFormatted [] > {
    //     console.log ( `DataSource_Applications.addApplications () : Adding application : ${URL_APPLICATIONS}.` );

    //     const promiseApplications = new Promise < any > ( async (resolve : Function, reject : Function) => {
    //         axios.post (
    //             URL_APPLICATIONS,
    //             { applications : inputApplications },
    //             { 
    //                 headers: {
    //                     "Authorization" : `Bearer ${token}`
    //                 }
    //             }
    //         )
    //         .then(function (response : any) {
    //             resolve (response.data);
    //         })
    //         .catch(function (error : any) {
    //             // handle error
    //             console.error(`DataSource_Applications.addApplications () : axios.post () error : ${JSON.stringify (error)} .`);
    //             if( error.response ){
    //                 console.error(`DataSource_Applications.addApplications () : ${JSON.stringify ( error.response.data )} .`);
    //             }
    //             reject ();
    //         });
    //     });

    //     const response = await promiseApplications;

    //     return response.data.applications;
    // }

    static async getApplications ( inputPage : number = 1, inputAmountPerPage : number = 20 ) : Promise < IMachineApplicationFromDataBaseFormatted [] > {
        // const Applications = [] as IApplicationInList [];

        console.log ( `DataSource_Applications.getApplications () : Receiving applications : ${URL_APPLICATIONS}.` );

        // const response = await axios.get(URL_APPLICATIONS);

        const promiseApplications = new Promise < any > ( async (resolve : Function, reject : Function) => {
            axios.get (
                `${URL_APPLICATIONS}?page=${inputPage}&limit=${inputAmountPerPage}`,
                { 
                    headers: {
                        "Authorization" : `Bearer ${token}`
                    }
                }
            )
            .then(function (response : any) {
                resolve ( response.data );
            })
            .catch(function (error : any) {
                // handle error
                console.error(`DataSource_Applications.getApplications () : axios.get () error : ${JSON.stringify (error)} .`);
                if( error.response ){
                    console.error(`DataSource_Applications.getApplications () : ${JSON.stringify ( error.response.data )} .`);
                }
                reject ();
            });
        });

        const response = await promiseApplications;

        // console.log ( response );
        // const Applications = JSON.parse ( stringApplications ) as IApplicationInList [];
            // .then(response => (
            //  this.info = response
            // ));

        // const applications = response.data.applications;

        return response.data.applications;
        // return [];
    }

    // static async getApplication ( inputId : string ) : Promise < IApplicationFromDataBaseFormatted | null > {
    //     const urlApplication = `${URL_APPLICATIONS}${inputId}/`;
    //     console.log ( `DataSource_Applications.getApplication () : Receiving application : ${urlApplication}.` );

    //     const promiseApplication = new Promise < any > ( async (resolve : Function, reject : Function) => {
    //         axios.get (
    //             urlApplication,
    //             { 
    //                 headers: {
    //                     "Authorization" : `Bearer ${token}`
    //                 }
    //             }
    //         )
    //         .then(function (response : any) {
    //             resolve ( response.data );
    //         })
    //         .catch(function (error : any) {
    //             // handle error
    //             console.error(`DataSource_Applications.getApplication () : axios.get () error : ${JSON.stringify (error)} .`);
    //             if( error.response ){
    //                 console.error(`DataSource_Applications.getApplication () : ${JSON.stringify ( error.response.data )} .`);
    //             }
    //             reject ();
    //         });
    //     });

    //     const response = await promiseApplication;

    //     return response.data.application;
    // }

    // static async updateApplication ( inputId : string, inputApplication : IApplicationInputParameters ) : Promise < void > {
    //     const urlApplication = `${URL_APPLICATIONS}${inputId}/`;
    //     console.log ( `DataSource_Applications.updateApplication () : Updating application : ${urlApplication}.` );

    //     const promiseApplication = new Promise < void > ( async (resolve : Function, reject : Function) => {
    //         axios.put (
    //             urlApplication,
    //             { application : inputApplication },
    //             { 
    //                 headers: {
    //                     "Authorization" : `Bearer ${token}`
    //                 }
    //             }
    //         )
    //         .then(function (response : any) {
    //             resolve ();
    //         })
    //         .catch(function (error : any) {
    //             // handle error
    //             console.error(`DataSource_Applications.updateApplication () : axios.put () error : ${JSON.stringify (error)} .`);
    //             if( error.response ){
    //                 console.error(`DataSource_Applications.updateApplication () : ${JSON.stringify ( error.response.data )} .`);
    //             }
    //             reject ();
    //         });
    //     });

    //     await promiseApplication;
    // }

    // static async deleteApplication ( inputId : string ) : Promise < void > {
    //     const urlApplication = `${URL_APPLICATIONS}${inputId}/`;
    //     console.log ( `DataSource_Applications.deleteApplication () : Deleting application : ${urlApplication}.` );

    //     const promiseApplication = new Promise < void > ( async (resolve : Function, reject : Function) => {
    //         axios.delete (
    //             urlApplication,
    //             { 
    //                 headers: {
    //                     "Authorization" : `Bearer ${token}`
    //                 }
    //             }
    //         )
    //         .then(function (response : any) {
    //             resolve ();
    //         })
    //         .catch(function (error : any) {
    //             // handle error
    //             console.error(`DataSource_Applications.deleteApplication () : axios.delete () error : ${JSON.stringify (error)} .`);
    //             if( error.response ){
    //                 console.error(`DataSource_Applications.deleteApplication () : ${JSON.stringify ( error.response.data )} .`);
    //             }
    //             reject ();
    //         });
    //     });

    //     await promiseApplication;
    // }
}
