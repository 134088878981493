
import { Options, Vue } from 'vue-class-component'

import { DataSource_Applications } from '@/systems/data_sources/DataSource_Applications'; // @ is an alias to /src
import { Utilities } from '@/systems/common_code/Utilities';

@Options({
	async mounted () {
		const preApplications = document.getElementById ( "preApplications" );
		const applications = await DataSource_Applications.getApplications ( 1, 350 );
		// console.log ( applications );
		if ( preApplications ) {
			// let sApplicationsHTML = "";
			// let i = 0;

			preApplications.innerHTML = '';

			// for ( i = 0; i < applications.length; i ++ ) { // applications/${applications [ i ].id
			// 	// ${sApplicationsHTML}
			// 	sApplicationsHTML = `<a href="#/applications/${applications [ i ].id}"><div>
			// 		<div>${applications [ i ].name}</div>
			// 		<div>${applications [ i ].description}</div>
			// 		<div>${applications [ i ].status}</div>
			// 		<div>${applications [ i ].configurationApplications.amountApplicationsToRun}</div>
			// 	</div></a>`;
			// 	// const application = await DataSource_Applications.getApplication ( applications [ i ].id );
			// 	// console.log ( application );
			// 	const elementsApplications = Utilities.htmlToElements ( sApplicationsHTML );
			// 	// console.log ( elementsApplications );
			// 	divApplications.append ( elementsApplications );
			// }
			// console.log ( sApplicationsHTML );
			// const elementsApplications = Utilities.htmlToElements ( sApplicationsHTML );
			// console.log ( elementsApplications );
			// divApplications.append ( elementsApplications );

			preApplications.append ( JSON.stringify ( applications, null, 2 ) );
		}
	},
	props: {
		msg: String
	}
})
export default class componentApplications extends Vue {
  msg!: string
}
