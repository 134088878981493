
import { Options, Vue } from 'vue-class-component'
import componentApplications from '@/components/Applications.vue' // @ is an alias to /src

@Options({
  components: {
    componentApplications
  }
})
export default class Applications extends Vue {}
