
import { Options, Vue } from 'vue-class-component'

import { DataSource_Projects } from '@/systems/data_sources/DataSource_Projects'; // @ is an alias to /src
import { Utilities } from '@/systems/common_code/Utilities';

@Options({
	async mounted () {
		const divProjects = document.getElementById ( "projects" );
		const projects = await DataSource_Projects.getProjects ();
		// console.log ( projects );
		if ( divProjects ) {
			let sProjectsHTML = "";
			let i = 0;

			divProjects.innerHTML = '';

			for ( i = 0; i < projects.length; i ++ ) { // projects/${projects [ i ].id
				// ${sProjectsHTML}
				sProjectsHTML = `<a href="#/projects/${projects [ i ].id}"><div>
					<div>${projects [ i ].name}</div>
					<div>${projects [ i ].description}</div>
					<div>${projects [ i ].status}</div>
					<div>${projects [ i ].configurationMachines.amountMachinesToRun}</div>
				</div></a>`;
				// const project = await DataSource_Projects.getProject ( projects [ i ].id );
				// console.log ( project );
				const elementsProjects = Utilities.htmlToElements ( sProjectsHTML );
				// console.log ( elementsProjects );
				divProjects.append ( elementsProjects );
			}
			// console.log ( sProjectsHTML );
			// const elementsProjects = Utilities.htmlToElements ( sProjectsHTML );
			// console.log ( elementsProjects );
			// divProjects.append ( elementsProjects );
		}
	},
	props: {
		msg: String
	}
})
export default class componentProjects extends Vue {
  msg!: string
}
