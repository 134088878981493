
import { Options, Vue } from 'vue-class-component'
import componentProject from '@/components/Project.vue' // @ is an alias to /src

@Options({
  components: {
    componentProject
  }
})
export default class Project extends Vue {}
